import React from 'react';

const Basket = ({ position }) => {
  return (
    <div className="basket" style={{ left: position }}>
    </div>
  );
};

export default Basket;
